<template>
    <div class="login-main">
        <div class="login-head">
            <div class="login-head-title">{{ $t("登录") }}</div>
            <!-- <div class="login-head-brief">{{$t('欢迎再次回来')}} ~</div> -->
        </div>
        <div class="login-body">
            <van-tabs v-model="type" color="#5d74f2">
                <van-tab :title="$t('密码登录')" name="1">
                    <div class="login-right">
                        <van-field
                                class="login-input"
                                style="width: 100%"
                                v-model="ruleForm.user"
                                :placeholder="$t('email')+'  '+$t('mobile')"
                        >
                            <template #left-icon>
                                <img class="login-icon" src="../assets/img/icon-user.png"/>
                            </template>
                        </van-field>
                        <van-field
                                class="login-input"
                                style="width: 100%"
                                type="password"
                                v-model="ruleForm.password"
                                :placeholder="$t('xt_yh_03')"
                        >
                            <template #left-icon>
                                <img class="login-icon" src="../assets/img/icon-password.png"/>
                            </template>
                        </van-field>
                        <!-- <div class="to-other-page" @click="$router.push('/register')">{{$t('去注册')}}</div> -->
                        <van-button
                                round
                                block
                                type="info"
                                class="login-btn"
                                @click="wapLogin"
                        >{{ $t("登录") }}
                        </van-button>
                    </div>
                </van-tab>
                <van-tab :title="$t('短信登录')" name="2">
                    <div class="login-right">
                        <van-field
                                class="login-input"
                                v-model="smsForm.mobile"
                                :placeholder="$t('contact_tel')"
                        >
                            <template #left-icon>
                                <img class="login-icon" src="../assets/img/icon-mobile.png"/>
                            </template>
                        </van-field>
                        <van-field
                                class="login-input"
                                v-model="areaName"
                                :placeholder="$t('国家区号')"
                                @focus="showArea"
                                @blur="hideArea"
                        >
                            <template #right-icon>
                                <van-icon name="close" @click.stop="clearPicker"/>
                                <div v-if="areaShow" class="picker-main">
                                    <div
                                            v-for="(item, index) in areaList"
                                            :key="'area' + index"
                                            class="picker-list"
                                            @click="selectPicker(index)"
                                    >
                                        {{ item.name }}
                                    </div>
                                </div>
                            </template>
                        </van-field>
                        <van-field
                                class="login-input"
                                style="width: 100%"
                                type="password"
                                v-model="smsForm.msg_code"
                                :placeholder="$t('xt_yh_03')"
                        >
                            <template #left-icon>
                                <img class="login-icon" src="../assets/img/icon-password.png"/>
                            </template>
                            <template #right-icon>
                                <van-button round block :disabled="countFlag" type="button" class="login-code-btn" @click="sendMsg">{{ !countFlag?$t("发送短信"):countNum+"S" }}</van-button>
                            </template>
                        </van-field>
                        <van-button round block type="info"
                                    class="login-btn"
                                    @click="smsLogin"
                        >{{ $t("登录") }}
                        </van-button>

                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>
<script>
    import {Toast} from "vant";

    export default {
        name: "Login",
        data() {
            return {
                type: "1",
                areaShow: false, // 区号选择框是否显示
                areaArr: [], // 总区号列表
                areaList: [], // 筛选后显示的列表
                areaIndex: -1, // 选中的总区号列表索引     提交的id值示例就是this.areaArr[this.areaIndex].id,如果areaIndex==-1就是没选区号。
                areaName: "", // 总区号搜索关键字
                ruleForm: {
                    user: "",
                    password: "",
                },
                smsForm:{
                    mobile:"",
                    areaCode:"",
                    obj:1,
                    msg_code:"",
                },
                // 倒计时周期
                countNum:60,
                // 用于倒计时标记，true-正在倒计时
                countFlag:false,
                // 定时器
                intervalBtn:{},
            };
        },
        mounted() {
            if(this.$store.state.token){
                this.$router.push("/home");
            }
            this.siteConfig();
        },
        watch: {
            areaName(val) {
                this.areaListHandel(val);
            }
        },
        methods: {
            siteConfig() {
                // 获取客户服务信息
                this.$http.post("/v1/siteConfig").then((res) => {
                    if (res.code == 200) {
                        this.areaArr = res.data.countrys;
                    }
                });
            },
            wapLogin() {
                // 登录
                this.$store.commit("setToken", '');
                this.$http
                    .post("/v1/wapLogin", {
                        user: this.ruleForm.user,
                        pass: this.ruleForm.password,
                        cross: "wap",
                        type: 1,
                        invite_code: localStorage.getItem("icode")
                            ? localStorage.getItem("icode")
                            : "",
                        kinvite_code: localStorage.getItem("kcode")
                            ? localStorage.getItem("kcode")
                            : "",
                    })
                    .then((res) => {
                        if (res.code == 200) {
                            this.$store.commit("setToken", res.data.token);
                            this.$store.commit("setIdentity", res.data.identity);
                            this.$router.push("/home");
                        }
                    });
            },
            smsLogin(){

                //短信注册
                this.$store.commit("setToken", '');
                this.$http.post("/v1/smsLogin", {
                    mobile: this.smsForm.mobile,
                    area_code: this.smsForm.areaCode,
                    obj: this.smsForm.obj,
                    msg_code:this.smsForm.msg_code,
                }).then((res) => {
                    if (res.code == 200) {
                        this.$store.commit("setToken", res.data.token);
                        this.$store.commit("setIdentity", res.data.identity);
                        this.$router.push("/home");
                    }
                });
            },
            areaListHandel(val) {
                //通过关键字筛选国家区号列表
                if (val) {
                    this.areaList = this.areaArr.filter(
                        (item) => item.name.indexOf(val) != -1
                    );
                } else {
                    this.areaList = this.areaArr;
                }
            },
            showArea() {
                //显示区号框
                this.areaListHandel(this.areaName);
                this.areaShow = true;
            },
            hideArea() {
                // 隐藏区号框
                setTimeout(() => {
                    this.areaShow = false;
                }, 0);
            },
            clearPicker() {
                // 清除国家区号选择
                this.areaIndex = -1;
                this.areaName = "";
            },
            selectPicker(index) {
                // 选择国家区号下拉项
                this.areaName = this.areaList[index].name;
                this.smsForm.areaCode = this.areaList[index].area_code;
                this.areaIndex = index;
                this.areaShow = false;
            },
            sendMsg(){

                if(this.countFlag){
                    return;
                }
                let that = this;
                //发送短信
                this.$http.post("/v1/sendSmsCode", {
                    mobile: this.smsForm.mobile,
                    areaCode: this.smsForm.areaCode,
                    obj: this.smsForm.obj,
                }).then((res) => {
                    if(res!='undefined'){
                        Toast(res.msg);
                        if (res.code == 200) {
                            that.countDown();
                        }
                    }
                });
            },
            countDown(){
                // 设置btn倒计时时显示的信息
                // 更改btn状态
                this.countFlag =! this.countFlag;
                // 设置倒计时
                this.intervalBtn = setInterval(() => {
                    if(this.countNum <= 0) {
                        // 重置btn提示信息
                        // 清除定时器
                        clearInterval(this.intervalBtn)
                        // 更改btn状态
                        this.countFlag =! this.countFlag;
                        // 重置倒计时状态
                        this.countNum = 5;
                    }
                    // 倒计时
                    this.countNum -- ;
                }, 1000);
            },
        },
    };
</script>
<style scoped>
    .login-main {
        padding-bottom: 50px;
        background: url(../assets/img/head.png);
        background-size: 100% 20%;
        /* background-size: contain; */
        background-repeat: no-repeat;
        min-height: calc(100vh - 50px);
        display: flex;
        flex-direction: column;
    }

    .login-head {
        padding: 0 45px;
        height: 20vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .login-head-title {
        font-size: 20px;
        font-weight: bold;
        color: white;
        margin-bottom: 5px;
    }

    .login-head-brief {
        color: white;
        font-size: 15px;
        font-weight: bold;
    }

    .login-body {
        margin-top: 5vw;
        width: 100vw;
    }

    .login-right {
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* box-shadow: 0 0 15px 0 #ccc; */
    }

    .van-cell {
        box-sizing: border-box;
    }

    .login-btn {
        font-size: 16px;
        background: #5d74f2;
        width: 40vw;
        border: none;
        height: 45px;
        margin: 15vw auto 0;
        box-shadow: 0 0 10px 0 #5d74f2;
    }

    .login-input {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        height: 50px;
        background: transparent;
    }
    .login-code-btn {
        font-size: 16px;
        background: #5d74f2;
        border: none;
        margin: auto;
        height: 30px;
        color: white;
        font-size: 14px;
    }

    .login-icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }

    .to-other-page {
        padding-right: 16px;
        text-align: right;
        font-size: 12px;
        color: #5d74f2;
    }
    .picker-main {
        position: absolute;
        top: 33px;
        right: 0;
        width: 100%;
        border: 1px solid #ccc;
        z-index: 1;
        background: white;
        border-radius: 3px;
        max-height: 300px;
        overflow: auto;
    }

    .picker-list {
        text-align: left;
        padding: 3px 10px;
        border-bottom: 1px solid #e1e1e1;
    }
    .van-cell::after {
        border-bottom: 1px solid #dedcf1;
        transform: scaleY(1);
    }
    .van-cell {
        width: 50%;
        overflow: initial !important;
    }
    .van-tabs {
        padding: 0 20px;
        margin-bottom: 10px;
    }
</style>
